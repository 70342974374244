<template>
    <v-app-bar class="nav" absolute :elevation="0">
        <img :class="$vuetify.display.mobile ? 'logo-mobile' : 'logo'"
        :src="require('../assets/MetOne-Logo.png')" @click="$router.push('/')"/>
        <div class="spacer"></div>
        <template v-if="!$vuetify.display.xs">
          <router-link :class="selected == 0 ? 'link sel' : 'link reg'" 
                       @click="handleClick(0)"
                       to="/">HOME</router-link>
          <router-link :class="selected == 1 ? 'link sel' : 'link reg'" 
                       @click="handleClick(1)"
                       to="/about">ABOUT</router-link>
          <p :class="selected == 2 ? 'link sel' : 'link reg'" 
              to="/products">
              PRODUCTS
              <v-menu activator="parent">
                  <v-card class="glass" color="transparent" height="200px">
                      <v-list bg-color="transparent"> 
                          <v-list-item
                              bg-color="transparent"
                              v-for="(fam, i) in families"
                              :key="i"
                              :value="i"
                              :to="'/products/'+fam"
                              class="link reg"
                              @click="handleClick(2)"
                          >
                              <span style="padding-left: 10px">{{ fam }}</span>
                          </v-list-item>
                      </v-list>
                  </v-card>
              </v-menu>
          </p>
          <router-link :class="selected == 3 ? 'link sel' : 'link reg'" 
                       @click="handleClick(3)"
                       to="/contact">CONTACT</router-link>
          <v-btn class="dist"
                 @click="handleClick(4)"
                 to="/distributor">DISTRIBUTOR</v-btn>
        </template>
        <template v-else>
          <v-app-bar-nav-icon 
            variant="text" 
            @click.stop="$emit('draw')"
            color="grey"
          >
          </v-app-bar-nav-icon>
        </template>
    </v-app-bar>
</template>

<script>
export default {
  name: 'NavComponent',
  components: {
  },
  props: {
      selected: Number,
  },
  data() {
    return {
        families: [
            "audere",
            "kodiak",
            "xultan",
            "czar",
            /*"sovering"*/
        ]
    };
  },
  methods: {
    handleClick(selected) {
        this.$emit('updateSelect', selected);
    },
  }
};
</script>

<style scoped>
.dist {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  padding: 5px 35px 5px 35px;
  margin-left: 50px;
  margin-right: 50px !important;
  border: 1px solid #68696E;
  background-color: #88C0D0; 
  color: white;
  border-radius: 5px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: thin;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.dist:hover {
  /*background-color: #5EBEF4; */
  text-decoration: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
}

.nav {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  margin-right: 30px;
  backdrop-filter: blur(9px);
  background-color: rgba(255, 255, 255, 0.4);
  /*background-color: transparent;*/
}

.link {
  padding: 10px 15px;
  color: #333; 
  text-decoration: none;
  text-transform: uppercase; 
  font-weight: thin; 
  transition: color 0.3s;
}

.link:hover {
  color: #555; 
}

.sel {
  color: #A2A5A5;
}

.reg {
  color: #68696E;
}

.spacer {
  width: 50vw;
}

.logo {
  width: 170px;
  margin-top: 20px;
  margin-left: 7vw;
  /*backdrop-filter: blur(9px);*/
  /*background-color: rgba(255, 255, 255, 0.3);*/
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
}

.logo-mobile {
  width: 170px;
  margin-top: 20px;
  /*backdrop-filter: blur(9px);*/
  /*background-color: rgba(255, 255, 255, 0.3);*/
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
}

.links {
  /*background-color: rgba(255, 255, 255, 0.3);*/
  /*backdrop-filter: blur(9px);*/
  border-radius: 20px;
  padding: 20px;
}

.glass {
  background-color: transparent;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.glass::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.3); 
}
</style>
