<template>
    <v-container>
        <v-row justify="end">
            <v-col cols="auto" class="rot" v-if="!$vuetify.display.xs && !$vuetify.display.md">
                <v-label class="side-label">Met One Tech</v-label>
                <v-label class="line">------------------------------------</v-label>
            </v-col>
            <v-col lg="11" xs=12>
                <div class="home-pic">
                    <div :class="$vuetify.display.xs ? 'mobile-glass-square' : 'glass-square'">
                        <p :class="$vuetify.display.xs ? 'mobile-text-inside' : 'text-inside'">Creating brighter futures for our
                        patients through our exciting advances and innovations in
                        designing medical implants.</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" style="padding-top: 30px">
            <v-col :cols="$vuetify.display.xs ? 12 : 5" style="padding-left: 0">
                <div :class="$vuetify.display.xs ? 'mobile-learn-more-comp' : 'learn-more-comp'">
                    <p :class="$vuetify.display.xs ? 'mobile-learn-more-text' : 'learn-more-text'">We're ensuring every patient receives the
                    care they deserve, supported by integrity, collaboration, and
                    teamwork. Join us on this journey today!</p>
                    <v-btn variant="outlined" color="#5E81AC" to="/contact">Learn More<!--<v-icon color=grey
                    style="padding-left: 10px">mdi-arrow-down</v-icon>--></v-btn>
                </div>
            </v-col>
            <v-col v-if="!$vuetify.display.xs">
                <v-spacer></v-spacer>
            </v-col>
            <v-col>
                <div :class="$vuetify.display.xs ? 'mobile-contact-us-comp' : 'contact-us-comp'">
                    <p :class="$vuetify.display.xs ? 'mobile-contact-us-text' : 'contact-us-text'">Since our inception we have been dedicated
                    to providing superior customer service. Please reach out to our team
                    today to learn more about us!</p>
                    <v-btn variant="outlined" color="#5E81AC" to="/about">Contact Us<!--<v-icon color=grey
                    style="padding-left: 10px">mdi-arrow-down</v-icon>--></v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',

  components: {
  },
});
</script>

<style scoped>
.side-label {
  font-size: 20pt;
  letter-spacing: 3pt;
}

.line {
  font-size: 20pt;
  font-weight: thin;
  padding-left: 20px;
  letter-spacing: -1.3px;
}

.rot {
  position: absolute;
  top: 44vh;
  left: -8.9vw;
  transform: rotate(-90deg);
}

.home-pic {
  border-radius: 107px; 
  height: 55vh;
  width: 100%;
  background-size: cover;
  background-position: center 9%;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  background-image: radial-gradient(circle at center, transparent, rgb(0, 0, 0, 0.2) 90%), url('../assets/docs.webp');
  display: flex;
  justify-content: start;
  align-items: center;
}

.glass-square {
  width: 30%;
  max-width: 40%;
  height: 50vh;
  max-height: 50vh;
  margin-left: 10%;
  border-radius: 20px;
  backdrop-filter: blur(8px);
  background-color: rgb(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
}

.mobile-glass-square {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 30%;
  border-radius: 80px;
  backdrop-filter: blur(8px);
  background-color: rgb(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
}

.text-inside {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.5); 
  padding: 40px;
}

.mobile-text-inside {
  font-size: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5); 
  padding: 30px;
}

.learn-more-comp {
  padding-top: 10px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.learn-more-text {
  font-size: 11pt;
  color: #68696E;
  padding-bottom: 10px;
}

.mobile-learn-more-comp {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-learn-more-text {
  font-size: 11pt;
  justify: center;
  padding: 20px;
  text-align: center;
  color: #68696E;
  padding-bottom: 10px;
}

.contact-us-comp {
  padding-top: 10px;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
}

.contact-us-text {
  font-size: 11pt;
  justify: end;
  text-align: right;
  color: #68696E;
  padding-bottom: 10px;
}

.mobile-contact-us-comp {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-contact-us-text {
  font-size: 11pt;
  padding: 20px;
  justify: center;
  text-align: center;
  color: #68696E;
  padding-bottom: 10px;
}
</style>
