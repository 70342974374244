<template>
  <v-app full-height>
    <v-main style="padding-top: 0">
      <v-dialog 
        v-model="disclaimer" 
        :max-width="($vuetify.display.mobile) ? '100vw' : '50vw'" 
        opacity="0" 
        scrollable
      >
        <v-card height="auto" style="padding: 25px" class="glass">
          <v-card-title style="z-index: 1" class="card-title">
            Disclaimer
          </v-card-title>
          <v-card-text justify="start" style="z-index: 1">
            <p class="card-subtitle">Privacy Policy</p>

            <p>Met One Technologies LLC is concerned about privacy issues and wants you
            to be familiar with how we collect, use and disclose information. This
            privacy policy describes our practices in connection with information that we
            or our service providers collect through the website or web property
            (including, for example, a mobile website or application) operated and
            controlled by us from which you are accessing this privacy policy (each, the
            “site”). By providing personal information to us or by using the site, you
            agree to the terms and conditions of this privacy policy.</p>

             

            <p class="card-subtitle">Legal Statements</p>

            <p>Copyright© Met One Technologies LLC. All rights reserved. The entire contents of this website are the sole property of Met One Technologies LLC. The copying, redistribution or publication of any part of this site, in whole or in part, is strictly prohibited without the express, written consent of Met One. We reserve the right to block any domains we choose from accessing this website. All product names, whether or not they appear in all capital letters or with a trademark symbol, are trademarks of Met One Technologies LLC. The use of these trademarks without the express, written consent of the company is prohibited and may be in violation of copyright law, trademark law, or other applicable laws including those governing libel, slander, publicity and privacy.</p>
             

            <p>The materials on this website do not in any way replace the relationship that you have with your physician and are for general educational information only. Met One Technologies LLC does not practice medicine, or provide medical services or advice, and the information on this website should not be considered medical advice. You should always talk to your physician for diagnosis and treatment.</p>

             

            <p class="card-subtitle">Disclaimer</p>

            <p>INFORMATION CONTAINED ON THIS WEBSITE, INCLUDING MET ONE TECHNOLOGIES LLC PRODUCT INFORMATION, IS PROVIDED “AS IS” WITHOUT ANY WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. Users agree that the access and use of this site and its contents is at their own risk. Met One Technologies LLC accepts no responsibility for typographical errors or technically inaccurate information contained on this website. Met One Technologies LLC specifically reserves the right to change, delete or update information without notice at any time; however, Met One accepts no obligation to update the information contained on this site.</p>

             

            <p class="card-subtitle">Surgical Procedures</p>

            <p>Submissions to the U.S. Food and Drug Administration or equivalent agency in other countries may contain surgical techniques that were reviewed as part of the approval or clearance process. These surgical techniques are provided for products when available and provide instructions consistent with the cleared indications for the associated product. While cleared and approved surgical techniques are provided on the website, the specific technique used is the choice of the surgeon. Met One Technologies LLC, as a manufacturer of medical devices, does not practice medicine and does not recommend these or any other surgical techniques for use on a particular patient. The surgeon who performs any implant procedure is responsible for determining and utilizing the appropriate techniques for implanting a prosthesis or implant in the particular patient. Met One Technologies LLC is not responsible for the selection of the appropriate surgical technique to be used for an individual patient. Questions regarding the appropriateness of a particular technique or device should be directed to the individual patient’s physician. Met One Technologies LLC does not promote or endorse in any way the use of a surgical technique that is not approved for use in the country where the reader is located.</p>

             

            <p class="card-subtitle">Product Information</p>

            <p>This website contains information about products that may or may not be available in any particular country or may be available under different trademarks in different countries. The products may be approved or cleared by governmental regulatory organizations for sale or use with different indications or restrictions in different countries. Products may not be approved for use in all countries. Nothing contained on this site should be construed as a promotion or solicitation for any product or for the use of any product in a particular way that is not authorized under the laws and regulations of the country where the reader is located. Met One Technologies LLC products have been approved or cleared for specific indications by the U.S. Food and Drug Administration or the equivalent agency in other countries. Met One does not promote or endorse in any way the use of Met One products for any indication that has not been approved or cleared in the country where the reader is located. Specific questions physicians may have about the availability and use of the products described on this site should be directed to their particular local sales representative. Specific questions patients may have about the use of the products described on this site or the appropriateness for their own conditions should be directed to their own physician. In addition, refer to the product package inserts for important information regarding Met One Technologies LLC products.</p>

             

            <p class="card-subtitle">Links to Other Sites</p>

            <p>This website may have links to web pages that are not associated with Met One Technologies LLC. Met One provides these links for your use, but we do not review, control or monitor the information on any other websites. Met One is not responsible for the content provided on those sites, does not guarantee in any way the accuracy of the information provided, and does not recommend any particular product or service contained on those linked sites. These websites may contain information about uses of Met One products that have not been approved in the country where the reader is located. Met One Technologies LLC does not control or endorse this information.</p>

             

            <p>© Met One Technologies LLC 2024. All rights reserved. This site is published by Met One Technologies LLC, which is solely responsible for its content.</p>

          </v-card-text>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        v-if="$vuetify.display.xs"
        v-model="drawer"
        location="left"
      >
        <v-row justify="center" class="text-center" style="margin-top: 20px">
          <v-col cols=12>
          <router-link :class="selected == 0 ? 'link sel' : 'link reg'" 
                       @click="handleClick(0)"
                       to="/">HOME</router-link>
          </v-col>
          <v-col cols=12>
          <router-link :class="selected == 1 ? 'link sel' : 'link reg'" 
                       @click="handleClick(1)"
                       to="/about">ABOUT</router-link>
          </v-col>
          <v-col cols=12>
          <p :class="selected == 2 ? 'link sel' : 'link reg'" 
              to="/products">
              PRODUCTS
              <v-menu activator="parent">
                  <v-card class="glass" color="transparent" height="200px">
                      <v-list bg-color="transparent"> 
                          <v-list-item
                              bg-color="transparent"
                              v-for="(fam, i) in families"
                              :key="i"
                              :value="i"
                              :to="'/products/'+fam"
                              class="link reg"
                              @click="handleClick(2)"
                          >
                              <span style="padding-left: 10px">{{ fam }}</span>
                          </v-list-item>
                      </v-list>
                  </v-card>
              </v-menu>
          </p>
          </v-col>
          <v-col cols=12>
          <router-link :class="selected == 3 ? 'link sel' : 'link reg'" 
                       @click="handleClick(3)"
                       to="/contact">CONTACT</router-link>
          </v-col>
          <v-col cols=12>
          <v-btn class="dist"
                 @click="handleClick(4)"
                 to="/distributor">DISTRIBUTOR</v-btn>
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <NavComponent :selected="selected" @updateSelect="updateSelect" style="position:
        sticky"
        @draw="toggleDrawer"
      ></NavComponent> 
      <router-view/>
      <v-footer inset class="footer">
        <span>© 2024 Met One Technologies LLC, All Rights Reserved.</span>
        <v-btn variant="tile"
               color="grey"
               @click="disclaimer=true"
        > 
               Disclaimer
        </v-btn>
        <v-btn 
          v-if="selected == 3"
          variant="tile" 
          color="grey"
          href=https://www.openstreetmap.org/copyright>
            © OpenStreetMap with Leaftlet
        </v-btn>
      </v-footer>
    </v-main> 
  </v-app>
</template>

<script>
import NavComponent from './components/NavComponent.vue'
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    NavComponent
  },
  data() {
      return {
          selected: 0,
          disclaimer: false,
          drawer: false,
          families: [
              "audere",
              "kodiak",
              "xultan",
              "czar",
              /*"sovering"*/
          ]
      };
  },
  mounted() {
      const router = useRouter();
      router.isReady().then(() => {
        switch(router.currentRoute.value.path) {
          case '/':
            this.selected = 0;
            break;
          case '/about':
            this.selected = 1;
            break;
          case '/products/audere':
          case '/products/kodiak':
          case '/products/xultan':
          case '/products/czar':
          case '/products/sovering':
            this.selected = 2;
            break;
          case '/contact':
            this.selected = 3;
            break;
          case '/distributor':
            this.selected = 4;
            break;
        }
      });
  },
  methods: {
      updateSelect(e) {
          this.selected = e;
      },
      toggleDrawer() {
        if (this.$vuetify.display.xs) {
          this.drawer = !this.drawer;
        } else {
          this.drawer = false;
        }
      },
      handleClick(selected) {
        this.selected = selected;
      }
  }
}
</script>

<style scoped>
.footer {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  color: grey;
}

.glass {
  background-color: transparent;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.glass::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5); 
}

.card-title {
  font-size: 15pt;
  font-family: "Inter", sans-serif;
  letter-spacing: 2pt;
  color: rgba(0, 0, 0, 0.8); 
  padding-bottom: 20px;
}

.card-subtitle {
  font-size: 12pt;
  font-family: "Inter", sans-serif;
  letter-spacing: 2pt;
  color: rgba(0, 0, 0, 0.4); 
  padding: 0;
  padding-left: 30px;
}

p {
  font-size: 11pt;
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 0.7); 
  padding: 30px;
  padding-top: 10px;
}

.dist {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  padding: 5px 35px 5px 35px;
  border: 1px solid #68696E;
  background-color: #88C0D0; 
  color: white;
  border-radius: 5px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  font-weight: thin;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.dist:hover {
  /*background-color: #5EBEF4; */
  text-decoration: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); 
}

.nav {
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  margin-right: 30px;
  backdrop-filter: blur(9px);
  background-color: rgba(255, 255, 255, 0.4);
  /*background-color: transparent;*/
}

.link {
  padding: 10px 15px;
  color: #333; 
  text-decoration: none;
  text-transform: uppercase; 
  font-weight: thin; 
  transition: color 0.3s;
}

.link:hover {
  color: #555; 
}

.sel {
  color: #A2A5A5;
}

.reg {
  color: #68696E;
}

.spacer {
  width: 50vw;
}

.logo {
  width: 170px;
  margin-top: 20px;
  margin-left: 7vw;
  /*backdrop-filter: blur(9px);*/
  /*background-color: rgba(255, 255, 255, 0.3);*/
  border-radius: 20px;
  padding: 20px;
}

.logo-mobile {
  width: 170px;
  margin-top: 20px;
  /*backdrop-filter: blur(9px);*/
  /*background-color: rgba(255, 255, 255, 0.3);*/
  border-radius: 20px;
  padding: 20px;
}

.links {
  /*background-color: rgba(255, 255, 255, 0.3);*/
  /*backdrop-filter: blur(9px);*/
  border-radius: 20px;
  padding: 20px;
}

.glass {
  background-color: transparent;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.glass::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.3); 
}
</style>
